import { Icon } from "@mui/material";
import PopupSuccessfulMessage from "components/PopupSuccessfulMessage";
import {
  CancelButton,
  ModalWrapper,
  SaveButton,
} from "components/styles/general-style";
import { ApprovalTranslation } from "config";
import { useCtx } from "context/SessionDataContext";
import { userWorkOrderListDto } from "DTO/userWorkOrderListDto";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FaSave, FaBackspace } from "react-icons/fa";
import { getWorkerOrderbyId, updateApproval } from "Services/Api";
import { ApprovalType } from "types";

type DeviationModelProps = {
  WOCaseId: string;
  data: ApprovalType;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  Inquirer: string;
  updateCase: (wOrder: userWorkOrderListDto) => void;
};
export function DeviationModel({
  WOCaseId,
  data,
  openModal,
  setOpenModal,
  Inquirer,
  updateCase,
}: Readonly<DeviationModelProps>) {
  const [checkboxState, setCheckboxState] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<{
    message: string;
    open: boolean;
  }>({ message: "", open: false });
  const { setAllWorkorders } = useCtx();

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const res = await updateApproval(WOCaseId);

    if (res?.Status !== "success") {
      setOpenPopup({
        message: "Gick inte att uppdatera ärendet ❌",
        open: true,
      });
      return;
    }
    const updatedWOrder = await getWorkerOrderbyId(WOCaseId);
    updateCase(updatedWOrder);
    setAllWorkorders((prev) =>
      prev.map((wO) =>
        wO.WOCaseId !== updatedWOrder.WOCaseId ? wO : updatedWOrder
      )
    );
  };

  return (
    <ModalWrapper>
      <Modal show={openModal} onHide={() => setOpenModal(false)} centered>
        {openPopup.open && (
          /** "SuccessfulMessage" 🙃 */
          <PopupSuccessfulMessage
            message={openPopup.message}
            onHide={() => setOpenPopup({ ...openPopup, open: false })}
          />
        )}

        <div className="deviation-modal-header">
          <Modal.Title>
            Handläggning: <strong>{WOCaseId}</strong>
          </Modal.Title>
        </div>
        <Modal.Body>
          <Form
            id="deviationForm"
            style={{ display: "table", width: "100%" }}
            onSubmit={submit}
          >
            {data.approvals.map((item) => (
              <DeviationField key={item} item={item} />
            ))}
          </Form>
        </Modal.Body>
        {data.accepted ? approvalText(Inquirer) : doneText(Inquirer)}
        <Modal.Footer>
          {!data.accepted && (
            <Form.Check
              className="deviationForm"
              form="deviationForm"
              type="checkbox"
              label={checkboxState ? "Godkänd" : "Acceptera"}
              checked={checkboxState}
              id={"deviationForm-checkbox"}
              onChange={() => setCheckboxState(!checkboxState)}
            />
          )}
          <CancelButton onClick={() => setOpenModal(false)}>
            {!data.accepted ? (
              <>
                <Icon style={{ overflow: "visible" }}>
                  <FaBackspace />
                </Icon>
                Avbryt
              </>
            ) : (
              "Stäng"
            )}
          </CancelButton>
          {!data.accepted && (
            <SaveButton
              disabled={!checkboxState}
              form="deviationForm"
              type="submit"
            >
              <Icon style={{ overflow: "visible" }}>
                <FaSave />
              </Icon>
              Spara
            </SaveButton>
          )}
        </Modal.Footer>
      </Modal>
    </ModalWrapper>
  );
}

type DeviationFieldProps = {
  item: string;
};

const approvalText = (Inquirer: string) => {
  return (
    <p style={{ padding: "15px", marginTop: "1rem" }}>
      Ärendet uppdaterat och klart. <strong>{Inquirer}</strong> ska nu ha
      tillgång till samtliga filer i{" "}
      <a target="_blank" href="https://portal.eningo.se/">
        Portalen
      </a>{" "}
    </p>
  );
};
const doneText = (Inquirer: string) => {
  return (
    <>
      <p
        style={{
          padding: "15px",
          textAlign: "center",
          borderBottom: "1px solid #ccc",
        }}
      >
        Alla fälten måste godkännas eller åtgärdas för att{" "}
        <strong>
          <a href={`mailto:${Inquirer}`}>{Inquirer}</a>
        </strong>{" "}
        ska få tillgång till samtliga filer i{" "}
        <a target="_blank" href="https://portal.eningo.se/">
          Portalen
        </a>{" "}
      </p>

      <small
        style={{
          fontSize: "0.7rem",
          width: "80%",
          margin: "0 auto 10px 10px",
        }}
      >
        *Genom att godkänna bekräftar du att Eningo AB inte kan hållas ansvarigt
        om de ovan nämnda uppgifterna inte har behandlats korrekt.
      </small>
    </>
  );
};

const DeviationField = ({ item }: DeviationFieldProps) => {
  const translation = ApprovalTranslation[item];

  return (
    <Form.Group
      style={{ display: "table-row" }}
      controlId={"formBasicCheckbox"}
    >
      <Form.Label className="deviationForm">{translation.label}:</Form.Label>
      <Form.Text className="text-muted deviationForm">
        {translation.description}
      </Form.Text>
    </Form.Group>
  );
};
