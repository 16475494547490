import { IUser } from "DTO/userWorkOrderListDto";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaSave } from "react-icons/fa";

import styled from "styled-components";
import { putUser } from "Services/Api";
import PopupSuccessfulMessage from "components/PopupSuccessfulMessage";
import {
  CancelButton,
  ModalWrapper,
  SaveButton,
} from "components/styles/general-style";

interface IUserModalProps {
  user: IUser;
  show: boolean;
  onHide: () => void;
}

const InputWrapper = styled.div<{
  cssInput?: string;
  cssLabel?: string;
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 16px;
  strong {
    margin-bottom: 8px;
    ${(props) => props.cssLabel ?? ""}
  }
  input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    ${(props) => props.cssInput ?? ""}
  }
`;

const WarningMessage = styled.p`
  color: #ff4444;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
`;

const Icon = styled.span`
  margin-right: 8px;
`;

export const UserModal: React.FC<IUserModalProps> = ({
  user,
  show,
  onHide,
}) => {
  const [showUserMessage, setShowUserMessage] = useState(false);
  const [noMatchPW, setNoMatchPW] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [warningMsg, setWarningMsg] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [repeatPWPH, setRepeatPWPH] = useState("Ange lösenord");
  const [nameValue, setNameValue] = useState(user.name);
  const [usernameValue, setUsernameValue] = useState(user.username);
  const [roleValue, setRoleValue] = useState(user.role);
  const [notMatchingPW, setNotMatchingPW] = useState("#000");

  const saveUser = async () => {
    try {
      if (
        newPassword === "" ||
        repeatPassword === "" ||
        currentPassword === ""
      ) {
        setNotMatchingPW("#FF4444");
        setRepeatPassword("");
        setRepeatPWPH("Får ej vara tomt");
        return;
      } else if (repeatPassword !== newPassword) {
        setNotMatchingPW("#FF4444");
        setRepeatPassword("");
        setNewPassword("");
        setRepeatPWPH("Matchar inte");
        setNoMatchPW(true);
        setWarningMsg("De lösenord du angav matchar inte. Försök igen.");
        return;
      }

      const updatedUser = {
        name: nameValue,
        username: usernameValue,
        current_password: currentPassword,
        new_password: newPassword,
      };
      const editUserData = await putUser(updatedUser);
      if (editUserData) {
        setShowUserMessage(true);
      }
    } catch (error) {
      setNoMatchPW(true);
      setWarningMsg("Oops, något gick fel. Försök igen.");
    }
  };

  const checkPWMatch = (e: any) => {
    setRepeatPassword(e.target.value);
    setNoMatchPW(false);
    if (e.target.value !== newPassword) {
      setNotMatchingPW("#FF4444");
    } else {
      setNotMatchingPW("#42DB89");
    }
  };

  return (
    <ModalWrapper>
      {showUserMessage ? (
        <PopupSuccessfulMessage
          message="Användaren har uppdaterats"
          onHide={onHide}
        />
      ) : (
        <Modal show={show} onHide={onHide}>
          <div className="modal-header d-flex justify-content-between align-items-center">
            <Modal.Title>Användardetaljer</Modal.Title>
          </div>
          <Modal.Body>
            {noMatchPW && <WarningMessage>{warningMsg}</WarningMessage>}
            <InputWrapper
              cssInput="
            background-color: #ccc;
            "
            >
              <strong>Namn:</strong>{" "}
              <input
                disabled
                type="text"
                value={nameValue}
                onChange={(e) => setNameValue(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper
              cssInput="
            background-color: #ccc;
            "
            >
              <strong>Användarnamn:</strong>{" "}
              <input
                disabled
                type="text"
                value={usernameValue}
                onChange={(e) => setNameValue(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper
              cssInput="
            background-color: #ccc;
            "
            >
              <strong>Roll:</strong>{" "}
              <input
                disabled
                type="text"
                value={roleValue}
                onChange={(e) => setNameValue(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <strong>Nuvarande lösenord:</strong>{" "}
              <input
                type="password"
                placeholder={repeatPWPH}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper
              cssInput={`
                border-bottom: 1px solid ${notMatchingPW};
                `}
            >
              <strong>Nytt lösenord:</strong>{" "}
              <input
                type="password"
                placeholder={repeatPWPH}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper
              cssInput={`
                border-bottom: 1px solid ${notMatchingPW};
                `}
            >
              <strong>Upprepa lösenord:</strong>
              <input
                type="password"
                placeholder={repeatPWPH}
                value={repeatPassword}
                onChange={(e) => checkPWMatch(e)}
              />
            </InputWrapper>
          </Modal.Body>
          <Modal.Footer>
            <CancelButton onClick={onHide}>Avbryta</CancelButton>
            <SaveButton onClick={saveUser}>
              {" "}
              <Icon>
                <FaSave />
              </Icon>
              Spara
            </SaveButton>
          </Modal.Footer>
        </Modal>
      )}
    </ModalWrapper>
  );
};
