import { useState } from "react";
import AWS from "aws-sdk";
import { FaEdit, FaTrash } from "react-icons/fa";
import { AiOutlineCloudUpload } from "react-icons/ai";

import {
  Button,
  ExButton,
  FileLoader,
  FileSelectBox,
  FileUploadBox,
  HeaderSection,
  IconContainerBox,
  SlectedFileInfoContainer,
  SuccessMessage,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableRow,
} from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faFile } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { HorizontalFlex, Sect, VerticalFlex } from "pandora/styled";
import { Container, Form, Modal } from "react-bootstrap";
import { PrefixIcon, SearchInputContainer } from "components/styles/nav-style";
import Plus06B4C8 from "resources/images/svg/plus06B4C8.svg";
import { deleteUser, getUsersList, postUser } from "Services/Api";
import { lightGrey } from "resources/newColors";
import moment from "moment";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { IUser } from "DTO/userWorkOrderListDto";
import styled from "styled-components";
import "./uploadImageToS3.scss";
import { UserModal } from "components/Modals";
import { useCtx } from "context/SessionDataContext";

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledButton = styled.button<{
  css?: string;
  bgColor?: string;
  hoverColor?: string;
  focusColor?: string;
}>`
  ${(props) => props.css ?? ""};
  border: none;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#01393F")};
  &:hover {
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : "#01393F"};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px
      ${(props) => (props.focusColor ? props.focusColor : "#01393F")};
  }
  svg {
    margin-right: 5px;
  }
`;

const UploadButton = styled.button`
  float: right;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #06b4c8;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ffffff;
  color: #06b4c8;
  &:hover {
    background-color: #0e89a0;
    color: #ffffff;
  }
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

const UploadImageToS3 = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeUser, setActiveUser] = useState<IUser>();
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [users, setUsers] = useState([]);
  const [newName, setNewName] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [newPasspowrd, setNewPassword] = useState("");
  const [newOrg, setNewOrg] = useState("");
  const [newRole, setNewRole] = useState("");
  const [newProfilePic, setNewProfilePic] = useState("");
  const [newPhone, setNewPhone] = useState(0);
  const [newIsActive, setNewIsActive] = useState(true);
  const [loggedUser, setLoggedUser] = useState({
    currentUser: "",
    currentUsername: "",
    tenant: "",
    tenantID: "",
  });
  const [successMessage, setSucessMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  //************************** */
  const [show, setShow] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const { appConfig } = useCtx();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const user1 = {
        currentUser: localStorage.getItem("user_name"),
        currentUsername: localStorage.getItem("username"),
        tenant: localStorage.getItem("user_org"),
        tenantID: localStorage.getItem("tenant_id"),
      };
      setLoggedUser(user1);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
    setSucessMessage("");
    setShowMessage(false);
  };
  const handleShow = () => setShow(true);

  const handleCloseFile = () => {
    setProgress(0);
    setSelectedFile(null);
    setShowFile(false);
  };

  const handleShowFile = () => setShowFile(true);
  const uploadAnotherFile = () => {
    setProgress(0);
    setSelectedFile(null);
  };

  const toggleRowExpansion = (index) => {
    /* Expands the user row on click*/
    const newExpandedRows = [...expandedRows];
    const rowIndex = expandedRows.indexOf(index);
    if (rowIndex >= 0) {
      newExpandedRows.splice(rowIndex, 1);
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  const renderTooltip = (props: any) => (
    /* A tooltip for delete button*/
    <Tooltip id="button-tooltip" {...props}>
      Ej tillåtet
    </Tooltip>
  );

  const handleShowModal = (user) => {
    setActiveUser(user);
    setShowModal(true);
  };

  const handleHideModal = () => setShowModal(false);

  const fetchTableData = async () => {
    try {
      const userWorkOrderListData = await getUsersList();
      if (userWorkOrderListData) {
        setUsers(userWorkOrderListData.Users);
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        localStorage.removeItem("token");
      }
    }
  };

  const handleNewUserChanges = () => {
    const newUser = {
      name: newName,
      username: newUsername,
      password: newPasspowrd,
      role: newRole,
      is_active: newIsActive,
    };
    postNewUser(newUser);
  };

  const postNewUser = async (newUser) => {
    const postedUser = await postUser(newUser);
    if (postedUser) {
      setShowMessage(true);
      setSucessMessage(newUser.username + " har skapats");
    }
  };

  const handleFileInput = (e) => {
    setProgress(0);
    setSelectedFile(e.target.files[0]);
  };

  const getFileExtension = (file) => {
    const d = file.name.split(".");
    const ext = d[d.length - 1];
    const na = d[0];
    const f = { ext, na };
    return f;
  };

  const uploadFile = (file) => {
    const f = getFileExtension(file);
    const dateAndTime = moment(file.lastModifiedDate).format("YYYYMMDDhhmmss");
    const fileKey =
      loggedUser.tenantID + "/" + f.na + "_" + dateAndTime + "." + f.ext;
    //console.log(fileKey);
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: appConfig["env-vars"].aws_bucket_name,
      Key: fileKey,
    };
    const myBucket = new AWS.S3({
      params: { Bucket: appConfig["env-vars"].aws_bucket_name },
      region: "eu-north-1",
      credentials: {
        accessKeyId: appConfig["env-vars"].aws_key,
        secretAccessKey: appConfig["env-vars"].aws_secret,
      },
    });

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  useEffect(() => {
    fetchTableData();
  }, [progress]);

  const handleDeleteUser = async (user: any) => {
    const userDeleted = await deleteUser(user);
    if (userDeleted) {
      window.location.reload();
    }
  };

  return (
    <>
      {showModal && (
        <UserModal
          user={activeUser}
          show={showModal}
          onHide={handleHideModal}
        />
      )}
      <Modal show={show} onHide={handleClose}>
        {showMessage && (
          <Modal.Header closeButton>
            <Modal.Title>{successMessage}</Modal.Title>
          </Modal.Header>
        )}
        {!showMessage && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Lägg till användare</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Namn</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ange namn"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Roll</Form.Label>
                  <Form.Control
                    as="select"
                    aria-label="Default select example"
                    value={newRole}
                    onChange={(e) => setNewRole(e.target.value)}
                  >
                    <option disabled value="">
                      Välj roll
                    </option>
                    <option value="Administratör">Administratör</option>
                    <option value="Fälttekniker">Fälttekniker</option>
                    <option value="Koordinator">Koordinator</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Användernamn</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ange användernamn"
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Lösenord</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Ange lösenord"
                    value={newPasspowrd}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose}>Avbryt</Button>
              <Button type="submit" onClick={handleNewUserChanges}>
                Bekräfta
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <Modal show={showFile} onHide={handleCloseFile}>
        <Modal.Header closeButton>
          <Modal.Title>Ladda upp Ledningskarta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileUploadBox
            css={`
              border: 1px solid ${lightGrey};
              border-radius: 10px;
              background-color: #ffffff;
              font-size: 1rem;
              padding: 0.5rem;
              width: 100%;
              word-break: break-word;
              font-weight: normal;
            `}
          >
            <div style={{ width: "100%" }}>
              {progress === 0 && (
                <FileSelectBox
                  css="
              font-size: 1.5rem;
              padding: 0.5rem 1rem;
              border: 3px dotted rgba(0,0,0,.1);
              padding: 1.5rem;
              background-color: #f1ffoj;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 1rem;
          "
                >
                  <IconContainerBox
                    css={`
                      border: none;
                    `}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </IconContainerBox>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    style={{ display: "none" }}
                    onChange={handleFileInput}
                  />
                  <label
                    style={{
                      border: `1px solid ${lightGrey}`,
                      backgroundColor: "rgba(255,255,255)",
                      color: "#000",
                      textTransform: "uppercase",
                      fontWeight: "700",
                    }}
                    htmlFor={"file"}
                  >
                    Välj en fil
                  </label>
                </FileSelectBox>
              )}
            </div>
            <SlectedFileInfoContainer>
              {selectedFile && (
                <>
                  {progress === 100 && (
                    <SuccessMessage>{`Uppladdning klar`}</SuccessMessage>
                  )}
                  <span>
                    <FontAwesomeIcon
                      icon={faFile}
                      style={{
                        fontSize: "1rem",
                        marginRight: "1rem",
                        color: "#06B4C8",
                      }}
                    />
                    {selectedFile.name}
                  </span>
                </>
              )}
            </SlectedFileInfoContainer>
          </FileUploadBox>
        </Modal.Body>
        <Modal.Footer>
          {progress !== 100 && (
            <>
              <Button onClick={handleCloseFile}>Stäng</Button>
              <Button onClick={() => uploadFile(selectedFile)}>
                {progress > 0 && progress < 99 ? <FileLoader /> : "Ladda upp"}
              </Button>
            </>
          )}

          {progress === 100 && (
            <Button onClick={uploadAnotherFile}>Ladda upp en till fil</Button>
          )}
        </Modal.Footer>
      </Modal>
      <VerticalFlex css="height: 100%; width: 100%;" className="uploadedData">
        <HorizontalFlex>
          <Sect>{loggedUser.tenant}</Sect>
          <UploadButton
            onClick={handleShowFile}
            style={{ whiteSpace: "nowrap" }}
          >
            <IconWrapper>
              <AiOutlineCloudUpload />
            </IconWrapper>
            Ladda upp fil
          </UploadButton>
        </HorizontalFlex>
        <HorizontalFlex css="height: 100%" className="uploadedData-table">
          <VerticalFlex css="width: 100%">
            <HorizontalFlex css="height: 100%; overflow-x: auto;">
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderSection>Namn</HeaderSection>
                    <HeaderSection>Användernamn</HeaderSection>
                    <HeaderSection>Roll</HeaderSection>
                    <HeaderSection>Status</HeaderSection>
                    <HeaderSection></HeaderSection>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, i: number) => (
                    <TableRow>
                      <TableData>{user.name}</TableData>
                      <TableData>{user.username}</TableData>
                      <TableData>{user.role}</TableData>
                      {user.is_active && <TableData>Aktiv</TableData>}
                      {!user.is_active && <TableData>Inaktiv</TableData>}
                      <TableData>
                        {user.username === loggedUser.currentUsername ? (
                          <ButtonsContainer>
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 0, hide: 100 }}
                              overlay={renderTooltip}
                            >
                              <StyledButton
                                bgColor="#cccccc"
                                hoverColor="#cccccc"
                                focusColor="#cccccc"
                              >
                                <FaTrash />
                                Radera
                              </StyledButton>
                            </OverlayTrigger>
                            <StyledButton
                              onClick={() => handleShowModal(user)}
                              bgColor="#06B4C8"
                              hoverColor="#33D4E7"
                              focusColor="#06B4C8"
                            >
                              <FaEdit />
                              Redigera
                            </StyledButton>
                          </ButtonsContainer>
                        ) : (
                          <StyledButton
                            onClick={() => toggleRowExpansion(i)}
                            bgColor="#FF5252"
                            hoverColor="#FF7676"
                            focusColor="#FF5252"
                          >
                            <FaTrash />
                            Radera
                          </StyledButton>
                        )}

                        {expandedRows.includes(i) && (
                          <div>
                            <ExButton
                              onClick={() =>
                                handleDeleteUser({
                                  name: user.name,
                                  username: user.username,
                                })
                              }
                              css="
                          background-color: #4CAF50;
                          color: #fff;
                          padding: 0px 6px;
                          margin: 10px;
                          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
                        "
                            >
                              Bekräfta
                            </ExButton>
                            <ExButton
                              onClick={() => toggleRowExpansion(i)}
                              css="
                          background-color: #FF4444;
                          color: #fff;
                          padding: 0px 6px;
                          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
                        "
                            >
                              Avbryta
                            </ExButton>
                          </div>
                        )}
                      </TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </HorizontalFlex>
            <SearchInputContainer
              css="
                  float: left;
                  margin: 35px 0 0;
                  width: 250px;
                  background-color: #FFFFFF;
                  color: #06B4C8;
                  border: solid 1px #06B4C8;
                  "
            >
              <PrefixIcon src={Plus06B4C8} />
              <Button
                onClick={handleShow}
                css="
            float: left;
            background-color: #FFFFFF;
            color: #06B4C8;
            border: solid 1px rgba(0,0,0,0);
              
            "
              >
                Lägg till användare
              </Button>
            </SearchInputContainer>
          </VerticalFlex>
        </HorizontalFlex>
      </VerticalFlex>
    </>
  );
};

export default UploadImageToS3;
