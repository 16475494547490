import { ApprovalTranslationType } from "types";

export const ApprovalTranslation: Readonly<ApprovalTranslationType> = {
  confidential: {
    label: "Konfidentiell",
    description: "Begäran om konfidentiella uppgifter",
  },
  totalRequestedArea: {
    label: "Efterfrågat område",
    description:
      "Godkännande för efterfrågat område som inte ligger inom tröskelvärdet för Bruksägare",
  },
  guardNeeded: {
    label: "Behova av gasvakt",
    description:
      "Gasvakt krävs för det utsatta området",
  },
};
