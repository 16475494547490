import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/sv";
import CrossAlt from "resources/images/svg/cross-black.svg";
import FileUpload from "resources/images/svg/fileUpload.svg";
import FileDone from "resources/images/svg/fileDone.svg";
import SendFile from "resources/images/svg/sendFile.svg";
import { handleBgColor, swedishIt } from "../providers/StylingFunc";
// import AWS from "aws-sdk";

import {
  HorizontalFlex,
  Sect,
  ProButton,
  SpaceBetweenRow,
  VerticalFlex,
} from "pandora/styled";
import { lightGrey, turquoise } from "resources/newColors";
import {
  Anchor,
  Button,
  FileLoader,
  FileSelectBox,
  FileUploadBox,
  FileUploadCard,
  IconButton,
  StatusCard,
  SuccessMessage,
} from "../views/authenticated/styled";
import { uploadCaseFile } from "Services/Api";
import { DeviationModel } from "./Modals";
import { ApprovalType, CaseLogType } from "types";
import { userWorkOrderListDto } from "DTO/userWorkOrderListDto";

type LeaseInfoProps = {
  WOCaseId: any;
  InquiryType: any;
  Status: any;
  StartDate: any;
  IquirerEmail: any;
  Location: any;
  Assignee: any;
  CaseAction: any;
  number_of_sites?: any;
  LogoutComponent?: any;
  onChangeWorkOrderStatus?: any;
  GisFileFormat?: any;
  FileCoordinateSystem?: any;
  AdditionalInfo: any;
  PropertyDesignation: any;
  updateLeaseInfo: (wOrder: userWorkOrderListDto) => void;
  Approvals?: ApprovalType;
  notes?: Array<CaseLogType>;
  //  Document: Array<string>;
};

const LeaseInfo = (props: LeaseInfoProps) => {
  const [activeHantering, setActiveHantering] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {
    WOCaseId,
    InquiryType,
    Status,
    StartDate,
    IquirerEmail,
    Location,
    Assignee,
    number_of_sites,
    LogoutComponent,
    CaseAction,
    GisFileFormat,
    FileCoordinateSystem,
    AdditionalInfo,
    PropertyDesignation,
    Approvals,
    updateLeaseInfo,
    notes,
    Document,
  } = props;

  useEffect(() => {
    setActiveHantering(false);
    setSelectedFile(null);
  }, [WOCaseId]);

  const handleHantering = () => {
    setActiveHantering(true);
  };

  const closeHantering = () => {
    setActiveHantering(false);
    setSelectedFile(null);
  };

  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (e) => {
    setProgress(0);
    setSelectedFile(e.target.files[0]);
  };
  const uploadFile = async (file, WOId) => {
    const uploadStatus = await uploadCaseFile(WOId, file);
    if (uploadStatus) {
      setTimeout(function () {
        markAsDone();
      }, 2000);
    }
  };

  //render tool tip
  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     När du skickar filen kommer den att markeras som klar
  //   </Tooltip>
  // );

  //change project staus
  const markAsDone = async (caseAction?: string) => {
    let newStatus = "done";
    const updatedStatus = await props.onChangeWorkOrderStatus(
      WOCaseId,
      newStatus,
      caseAction
    );
    if (updatedStatus) {
      setActiveHantering(false);
    }
  };
  return (
    <>
      <VerticalFlex css="width: 100%;">
        <SpaceBetweenRow css="margin-top: 20px">
          <HorizontalFlex css="font-weight: 600; font-size: 16px;">
            <Sect
              css={`
                color: ${turquoise};
                margin-right: 5px;
                width: auto;
              `}
            >
              #
            </Sect>
            {WOCaseId}
          </HorizontalFlex>
          {Approvals?.accepted === false && (
            <StatusCard
              css={`
                font-size: 13px;
                text-align: center;
                background-color: ;
              `}
            >
              <ProButton
                color={`${handleBgColor("approvals")}`}
                hoverColor="#e57575"
                onClick={() => setOpenModal(true)}
                style={{
                  borderRadius: "4px",
                  color: "#FFF",
                  fontSize: "10px",
                  padding: "10px",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}
              >
                Handlägg ärende
              </ProButton>
            </StatusCard>
          )}
          {InquiryType === "ProjectInquiry" &&
            Status === "unassigned" &&
            (LogoutComponent || (
              <StatusCard
                css={`
                  font-size: 13px;
                  text-align: center;
                  background-color: ;
                `}
              >
                <ProButton
                  color={`${handleBgColor(Status)}`}
                  hoverColor="#e57575"
                  onClick={() => markAsDone("no_action")}
                  style={{
                    borderRadius: "4px",
                    color: "#FFF",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontSize: "10px",
                    padding: "10px 18px",
                  }}
                  css="margin-bottom:0px; margin-right:10px; @media (max-width: 1455px) {margin-bottom:10px; margin-right:0px;}"
                >
                  Avsluta ärende
                </ProButton>
                {CaseAction !== "no_action" && (
                  <ProButton
                    color={`${handleBgColor(Status)}`}
                    hoverColor="#e57575"
                    onClick={handleHantering}
                    style={{
                      borderRadius: "4px",
                      color: "#FFF",
                      textTransform: "uppercase",
                      fontWeight: "700",
                      fontSize: "10px",
                      padding: "10px 18px",
                    }}
                  >
                    Hantera ärende
                  </ProButton>
                )}
              </StatusCard>
            ))}
          {InquiryType !== "ProjectInquiry" &&
            (LogoutComponent || (
              <StatusCard
                css={`
                  font-size: 13px;
                  text-align: center;
                  background-color: ${handleBgColor(Status)};
                `}
              >
                {swedishIt(Status, InquiryType)}
              </StatusCard>
            ))}
        </SpaceBetweenRow>
        {activeHantering && (
          <>
            {/*  {Document.filter((doc) => doc.endsWith(".pdf")).map((pdf) => (
              <a key={pdf} href={pdf} target="_blank">
                {pdf}
              </a>
            ))} */}
            <IconButton
              css={
                "margin-bottom: 20px; position: relative; width: 18px; color: black; height: auto; left: 95%; top: 10px; z-index: 100"
              }
              src={CrossAlt}
              onClick={closeHantering}
            ></IconButton>

            <FileUploadCard
              css="
          border : 1px solid #06B4C8;
          border-radius: 10px;
          background-color: #3D99F5;
          font-size: 1rem;
          word-break: break-word;
          margin-bottom: 10px;
        "
            >
              <FileUploadBox
                css="
                flex-flow: row wrap;
                padding: 1rem;
                "
              >
                <div style={{ width: "100%" }}>
                  {CaseAction !== "no_action" && (
                    <FileSelectBox
                      css="
                  font-size: 1rem;
                  padding: 0.5rem 1rem;
                  border: 2px dashed rgba(0, 0, 0, 0.15);
                  padding: 1rem;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin-bottom: 1rem;
              "
                    >
                      {selectedFile ? (
                        <>
                          {progress === 100 && (
                            <SuccessMessage>{`Uppladdningen lyckades`}</SuccessMessage>
                          )}
                          {progress === 0 && (
                            <>
                              <span style={{ color: "white" }}>
                                <img
                                  width={50}
                                  style={{
                                    filter:
                                      "invert(100%) sepia(100%) saturate(0%) hue-rotate(312deg) brightness(102%) contrast(105%)",
                                  }}
                                  src={FileDone}
                                />
                                {selectedFile.name}
                              </span>
                              {/* <OverlayTrigger
                              placement="left"
                              delay={{ show: 0, hide: 300 }}
                              overlay={renderTooltip}
                            > */}
                              <Button
                                style={{
                                  border: `1px solid ${lightGrey}`,
                                  backgroundColor: "rgba(255,255,255)",
                                  borderRadius: "4px",
                                  color: "#000",
                                  textTransform: "uppercase",
                                  fontWeight: "700",
                                  fontSize: "10px",
                                  padding: "10px 18px",
                                  marginTop: 30,
                                }}
                                onClick={() =>
                                  uploadFile(selectedFile, WOCaseId)
                                }
                              >
                                {progress > 0 && progress < 99 ? (
                                  <FileLoader />
                                ) : (
                                  "Skicka"
                                )}
                              </Button>
                              {/* </OverlayTrigger> */}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            type="file"
                            id="file"
                            name="file"
                            style={{ display: "none" }}
                            onChange={handleFileInput}
                          />
                          <img
                            style={{
                              paddingBottom: 20,
                              filter:
                                "invert(100%) sepia(100%) saturate(0%) hue-rotate(312deg) brightness(102%) contrast(105%)",
                            }}
                            width={50}
                            src={SendFile}
                          />
                          <label
                            style={{
                              border: `1px solid ${lightGrey}`,
                              backgroundColor: "rgba(255,255,255)",
                              borderRadius: "4px",
                              color: "#000",
                              textTransform: "uppercase",
                              fontWeight: "700",
                              fontSize: "10px",
                              padding: "10px 18px",
                              transition: "backgroundColor 1s",
                            }}
                            htmlFor={"file"}
                          >
                            <img
                              style={{ paddingRight: 10 }}
                              width={30}
                              src={FileUpload}
                            />
                            {GisFileFormat === "" && <>Välj en fil</>}
                            {GisFileFormat !== "" && (
                              <>Välj en {GisFileFormat} fil</>
                            )}
                          </label>
                        </>
                      )}
                    </FileSelectBox>
                  )}
                </div>
              </FileUploadBox>
            </FileUploadCard>
          </>
        )}
        {!activeHantering && (
          <>
            {infoField("Plats", Location)}
            {infoField("Startdatum", moment(StartDate).format("DD MMM YYYY"))}
            {infoField(
              "Förfrågare",
              <Anchor
                hoverColor={turquoise}
                color={turquoise}
                css={`
                  color: ${turquoise};
                  text-decoration: underline;
                `}
                href={`mailto:${IquirerEmail}`}
              >
                {IquirerEmail}
              </Anchor>
            )}
            {InquiryType === "ProjectInquiry" &&
              infoField(
                "Fil format",
                GisFileFormat
                  ? `${GisFileFormat}(${FileCoordinateSystem})`
                  : `-${GisFileFormat}`
              )}
            {InquiryType !== "ProjectInquiry" &&
              infoField("Tekniker", Assignee ?? "Otilldelad")}

            {number_of_sites && infoField("Antal område", number_of_sites)}
            {infoField("Kommentar", AdditionalInfo)}
            {infoField("Fastighetsbeteckning", PropertyDesignation)}
            {notes?.map((note) =>
              infoField("Anteckning", note.Activity.replace("ANTECKNING: ", ""))
            )}
          </>
        )}
      </VerticalFlex>
      {openModal && (
        <DeviationModel
          WOCaseId={WOCaseId}
          data={Approvals}
          openModal={openModal}
          setOpenModal={setOpenModal}
          Inquirer={IquirerEmail}
          updateCase={updateLeaseInfo}
        />
      )}
    </>
  );
};

const infoField = (key: string, value: string | JSX.Element) => {
  return (
    <HorizontalFlex css="font-size: 11px; margin-top: 10px;">
      <Sect css="font-weight: 600; flex: 2">{key}</Sect>
      <Sect
        css="flex: 4; text-align: left;
justify-content: flex-start;
padding-left:4px"
      >
        {value}
      </Sect>
    </HorizontalFlex>
  );
};
export default LeaseInfo;
