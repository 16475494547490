export const checkIsEmail = (email: string): boolean => {
  const emailRegexp =       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegexp.test(email);
}

export const checkIsPassword = (password: string): boolean => {
    const passwordRegexp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,15}/;
    return passwordRegexp.test(password);
}

export const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

type Entry = { [key: string]: any };
/**
 * Sort ascending / descending
 * Can be used in table to sort content 
 */
export function toSorted(
  a: Entry,
  b: Entry,
  sortColumn: keyof Entry,
  sortAscending: boolean,
) {
  if (a[sortColumn] === undefined && b[sortColumn] === undefined) return 0;
  if (a[sortColumn] === undefined) return sortAscending ? -1 : 1;
  if (b[sortColumn] === undefined) return sortAscending ? 1 : -1;
  const valA = a[sortColumn]?.toString() || "";
  const valB = b[sortColumn]?.toString() || "";

  const comparison = valA.localeCompare(valB, "sv", { sensitivity: "base" });
 if(comparison)
  return sortAscending ? comparison : -comparison;
return 0;
}