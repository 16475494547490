import React, { useEffect, useState, useRef } from "react";

import { Row, Col, Spinner } from "react-bootstrap";
import { Select, InputLabel, MenuItem, FormControl } from "@mui/material";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactMapGl, { Marker } from "react-map-gl";
import moment from "moment";
import IconTint from "react-icon-tint";

import { getChartData } from "Services/Api";

import {
  handleAdminBgColorMarker,
  handleGraphColorMarker,
  handleGraphBGColorMarker,
  handleGraphLanguages,
} from "../../providers/StylingFunc";

import Chart from "../../components/Chart/MultiaxisChart";
import capture1 from "../../assets/capture1.png";
import capture3 from "../../assets/capture3.png";
import capture4 from "../../assets/capture4.png";
import capture5 from "../../assets/capture5.png";
import capture6 from "../../assets/capture6.jpg";
import capture7 from "../../assets/capture7.png";
import capture8 from "../../assets/capture8.png";
import capture9 from "../../assets/capture9.jpg";
import construction2 from "../../assets/construction2.png";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Minus } from "../../assets/minus.svg";

import "../../components/styles/Dashboard.css";
import "react-dropzone-uploader/dist/styles.css";
import CheckboxTree from "./CheckboxTree";
import { useCtx } from "context/SessionDataContext";
import { set } from "date-fns";
import { fi } from "date-fns/locale";
import { CaseModal } from "components/Modals";

const Admin = () => {
  const [viewport, setViewport] = useState({
    longitude: 17.0999996,
    latitude: 60.0999996,
    zoom: 4,
    width: "100%",
    height: "60vh",
    margin: "auto",
  });
  const localMetrics = localStorage.getItem("metrics");
  const parsedMetrics = JSON.parse(localMetrics);
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [activeCase, setActiveCase] = useState(parsedMetrics.AssignedCount);
  const [issueCaseCount, setIssueCaseCount] = useState(
    parsedMetrics.IssueCount
  );
  const [unassignedCount, setUnassignedCount] = useState(
    parsedMetrics.UnassignedCount
  );
  const [projectCount, setProjectCount] = useState(parsedMetrics.ProjectCount);
  const [ongoingProjectCount, setOngoingProjectCount] = useState(
    parsedMetrics.OngoingProjectCount
  );
  const [mapCaseType, setMapCaseType] = useState("all");
  const [chartLoading, setChartLoading] = useState(true);
  const [markerLoading, setMarkerLoading] = useState(true);
  const [newsLoader, setNewsLoader] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [activeCaseId, setActiveCaseId] = useState();
  const [newsData, setNewsdata] = useState([]);
  const [graphData, setgraphData] = useState([]);
  let currentYear = moment().format("YYYY");
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [alignment, setAlignment] = useState(
    "mapbox://styles/selvincephusj/cknnge2gr46ef17ny95fk8yt7"
  );
  const [mapFilter, setMapFilter] = useState({
    all: false,
    project: { assigned: false, unassigned: false },
    cable: { assigned: false, unassigned: false },
  });

  const mapRef: any = useRef(null);
  const { workOrderListData, appConfig } = useCtx();
  useEffect(() => {
    setMarkerLoading(true);
    setFilteredAllData(
      workOrderListData.filter(
        (item) =>
          item.Status === "unassigned" &&
          (item.Type === "cable indication" || item.Type === "project")
      )
    );
    setMarkerLoading(false);
  }, [workOrderListData]);

  useEffect(() => {
    getNotifications();
  }, [filteredAllData]);

  useEffect(() => {
    let cancelled = false;
    // get GraphData
    const getGraphData = async (id) => {
      let chartData = [];
      let totalData = new Array(labels.length).fill(0);
      try {
        const response = await getChartData(parseInt(id));
        if (cancelled) return;
        response.map((item: any) => {
          chartData.push({
            data: item.data,
            label: handleGraphLanguages(item.label),
            borderColor: handleGraphColorMarker(item.label),
            backgroundColor: handleGraphBGColorMarker(item.label),
          });
        });

        chartData.map((s) => ({
          ...s,
          data: s.data.map((d, i) => {
            totalData[i] = totalData[i] + d;
            return d;
          }),
        }));

        chartData.push({
          data: totalData,
          backgroundColor: "rgba(4, 59, 92, 1)",
          borderColor: "rgb(4, 59, 92)",
          label: "Alla ärenden",
        });

        setgraphData(chartData);
        setChartLoading(false);
      } catch (e: any) {
        console.log(e);
      }
    };
    getGraphData(selectedYear);

    return () => {
      cancelled = true;
    };
  }, [selectedYear]);

  const handleChangeYear = (e: any) => {
    setChartLoading(true);
    setSelectedYear(e.target.value);
  };

  /*chart things*/
  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Statistik över alla inkomna ärenden i år",
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        display: true,
        position: "left" as const,
      },
    },
  };

  const labels = [
    "jan",
    "feb",
    "mars",
    "april",
    "maj",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "dec",
  ];

  const data = {
    labels,
    datasets: graphData,
  };

  //get notifications data
  const getNotifications = () => {
    setNewsdata(filteredAllData.slice(0, 5));
    setNewsLoader(false);
  };

  //set the viewport
  const onViewportChange = (newViewport) => {
    setViewport(newViewport);
  };

  //render the markers on the map
  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip">
        {!(Math.floor(viewport.zoom) < 10) && (
          <div style={{ backgroundColor: "black" }}>{props?.Id}</div>
        )}
        <div
          style={{
            backgroundColor: handleAdminBgColorMarker(props?.Status, ""),
          }}
        >
          {!(Math.floor(viewport.zoom) < 10) && <strong>Benämning : </strong>}
          {Math.floor(viewport.zoom) < 10 ? props?.length : props?.Name}
        </div>
      </Tooltip>
    );
  };

  const handleChange = (name) => {
    setAlignment(name);
  };

  const handleZoomIn = () => {
    setViewport((prevViewport) => ({
      ...prevViewport,
      zoom: Math.floor(prevViewport.zoom) + 2,
    }));
  };

  const handleZoomOut = () => {
    setViewport((prevViewport) => ({
      ...prevViewport,
      zoom: Math.floor(prevViewport.zoom) - 2,
    }));
  };

  return (
    <>
      {modalShow && (
        <CaseModal
          activeCaseId={activeCaseId}
          ReactMapGl={ReactMapGl}
          show={modalShow}
          onHide={() => setModalShow(false)}
          allOrderData={filteredAllData}
          setModalShow={setModalShow}
        />
      )}
      <div className="adminContain">
        <Row className="mainContain">
          <Col lg="9">
            <Row>
              <Col className="blockPadding mb-4" lg="4">
                <div className="threeBlock rounded">
                  <div className="displayFlex">
                    <div className="rectanglePeach rectangleBlueLight" />
                    <div className="headerText">Utsättning pågår</div>
                  </div>

                  <div className="boxValue">{activeCase && activeCase}</div>
                </div>
              </Col>
              <Col className="blockPadding mb-4" lg="4">
                <div className="threeBlock blockSecond rounded">
                  <div className="displayFlex">
                    <div className="rectangleBlue rectanglePeach" />
                    <div className="headerText">Ledningsanvisning</div>
                  </div>

                  <div className="boxValue">
                    {unassignedCount && unassignedCount}
                  </div>
                </div>
              </Col>
              <Col className="blockPadding mb-4" lg="4">
                <div className="threeBlock blockThird rounded">
                  <div className="displayFlex">
                    <div className="rectangleYellow" />
                    <div className="headerText">Projektering</div>
                  </div>
                  <div className="boxValue">{ongoingProjectCount}</div>
                </div>
              </Col>
            </Row>
            <div className="mapCol mb-4">
              {appConfig && (
                <ReactMapGl
                  ref={mapRef}
                  {...viewport}
                  mapboxApiAccessToken={appConfig["env-vars"]?.mapbox}
                  onViewportChange={onViewportChange}
                  mapStyle={alignment}
                  style={{ margin: "auto", width: "100%" }}
                >
                  {markerLoading ? (
                    <Spinner
                      style={{ marginTop: "30%" }}
                      variant="light"
                      animation="border"
                      role="status"
                    ></Spinner>
                  ) : (
                    filteredAllData.length > 0 &&
                    filteredAllData.map((data, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          if (Math.floor(viewport?.zoom) > 10) {
                            setActiveCaseId(data?.Id);
                            setModalShow(true);
                          }
                        }}
                      >
                        <Marker
                          longitude={data?.Center?.WGS84[0][1]}
                          latitude={data?.Center?.WGS84[0][0]}
                          offsetLeft={-20}
                          offsetTop={-20}
                        >
                          <OverlayTrigger overlay={renderTooltip(data)}>
                            <div
                              className="constructionImg"
                              style={{
                                backgroundColor: handleAdminBgColorMarker(
                                  data?.Status,
                                  data?.Type
                                ),
                              }}
                            >
                              <IconTint
                                maxHeight={25}
                                maxWidth={25}
                                src={construction2}
                                color={
                                  Math.floor(viewport?.zoom) < 10
                                    ? "transparent"
                                    : "transparent"
                                }
                              />
                            </div>
                          </OverlayTrigger>
                        </Marker>
                      </div>
                    ))
                  )}

                  <div
                    className="btn-group mapAllBtns"
                    role="group"
                    aria-label="Basic example"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <div className="mapViewBtn">
                        <button
                          type="button"
                          className={
                            alignment ===
                            "mapbox://styles/selvincephusj/cknnge2gr46ef17ny95fk8yt7"
                              ? "btn activeMap"
                              : "btn"
                          }
                          onClick={() =>
                            handleChange(
                              "mapbox://styles/selvincephusj/cknnge2gr46ef17ny95fk8yt7"
                            )
                          }
                        >
                          Flygfoto
                        </button>
                        <button
                          type="button"
                          className={
                            alignment === "mapbox://styles/mapbox/streets-v12"
                              ? "btn activeMap"
                              : "btn"
                          }
                          onClick={() =>
                            handleChange("mapbox://styles/mapbox/streets-v12")
                          }
                        >
                          Karta
                        </button>
                      </div>
                    </div>
                    <div>
                      <button onClick={handleZoomIn} className="iconBtn">
                        <Plus />
                      </button>
                      {Math.floor(viewport?.zoom) < 1 ? (
                        <></>
                      ) : (
                        <button onClick={handleZoomOut} className="iconBtn">
                          <Minus />
                        </button>
                      )}
                    </div>
                  </div>
                </ReactMapGl>
              )}
            </div>
          </Col>
          <Col lg="3" className="chartCol mb-4">
            <div className="card p-3 card-block " style={{ height: "100%" }}>
              <div className="d-flex justify-content-between align-items-center card-block-head">
                <h4>Nya ärenden</h4>
              </div>
              <div
                className="cardBody"
                style={{ height: "712px", overflowY: "auto" }}
              >
                {newsLoader ? (
                  <div className="d-flex justify-content-center p-4 align-items-center">
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                ) : (
                  <>
                    {newsData.length > 0 &&
                      newsData.map((data) => (
                        <div
                          key={data?.WOCaseId + data?.Location}
                          className="d-flex cardBody-list"
                        >
                          <div className="flex-shrink-0"></div>
                          <div className="flex-grow-1 ms-3 text-left ml-2">
                            <strong>{data?.WOCaseId}</strong>
                            <span> {data?.Location}</span>
                            <span>
                              {moment(data?.StartDate).format("DD MMM YYYY")}
                            </span>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col lg="12">
            <Row>
              <Col lg="4" className="chartCol mb-4">
                <div className="card p-3 card-block">
                  <div className="d-flex justify-content-between align-items-center card-block-head">
                    <h4>Nyheter</h4>
                  </div>
                  <div
                    style={{ height: "calc(526px - 59px)", overflowY: "auto" }}
                  >
                    <a
                      className=" cardBody-list"
                      href="https://www.eningo.se/blogg/praktik-hos-eningo"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src={capture8}
                            alt="sunset"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3 text-left ml-2">
                          <strong>Praktik hos Eningo</strong>{" "}
                          <span>11 januari 2024</span>
                        </div>
                      </div>
                    </a>
                    <a
                      className=" cardBody-list"
                      href="https://www.eningo.se/blogg/nu-lanserar-vi-eningo-portal"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src={capture9}
                            alt="sunset"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3 text-left ml-2">
                          <strong>Nu lanserar vi Eningo Portal</strong>{" "}
                          <span>10 oktober 2023</span>
                        </div>
                      </div>
                    </a>
                    <a
                      className=" cardBody-list"
                      href="https://www.eningo.se/blogg/falkenberg-energi-sparar-upp-till-2-timmar-om-dagen-pa-ledningsanvisning"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src={capture1}
                            alt="sunset"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3 text-left ml-2">
                          <strong>
                            Falkenberg Energi sparar upp till 2 timmar om dagen
                            på Ledningsanvisning
                          </strong>{" "}
                          <span>8 juni 2023</span>
                        </div>
                      </div>
                    </a>
                    <a
                      className=" cardBody-list"
                      href="https://www.eningo.se/blogg/eningo-2-0-ar-nu-live"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src={capture3}
                            alt="sunset"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3 text-left ml-2">
                          <strong>Eningo 2.0 är nu live!</strong>{" "}
                          <span>19 maj 2023</span>
                        </div>
                      </div>
                    </a>
                    <a
                      className=" cardBody-list"
                      href="https://www.eningo.se/blogg/digitaliseringen-i-byggbranschen-har-en-enorm-potential-men-gar-det-for-langsamt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src={capture4}
                            alt="sunset"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3 text-left ml-2">
                          <strong>
                            Digitaliseringen i byggbranschen har en enorm
                            potential. Men går det för långsamt?
                          </strong>{" "}
                          <span>20 april 2023</span>
                        </div>
                      </div>
                    </a>
                    <a
                      className=" cardBody-list"
                      href="https://www.eningo.se/blogg/miss-vid-gravarbeten-ar-en-stor-orsak-till-stromavbrott"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src={capture5}
                            alt="sunset"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3 text-left ml-2">
                          <strong>
                            {" "}
                            Misstag vid grävarbeten är en stor orsak till
                            strömavbrott
                          </strong>{" "}
                          <span>23 mars 2022</span>
                        </div>
                      </div>
                    </a>
                    <a
                      className=" cardBody-list"
                      href="https://www.eningo.se/blogg/eningo-utility-platform-celebrating-6-months"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src={capture6}
                            alt="sunset"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3 text-left ml-2">
                          <strong>
                            Eningo Utility Platform firar sex månader
                          </strong>{" "}
                          <span>9 juni 2022</span>
                        </div>
                      </div>
                    </a>
                    <a
                      className=" cardBody-list"
                      href="https://www.eningo.se/blogg/automatisering-av-ledningsanvisning-med-eningos-plattform"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src={capture7}
                            alt="sunset"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3 text-left ml-2">
                          <strong>
                            {" "}
                            Automatisering av lendningsanvisning med Eningos
                            plattform
                          </strong>{" "}
                          <span>19 februari 2022</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg="8" className="chartCol mb-4">
                <div
                  className="card p-3 card-block chartCard d-flex align-items-center justify-content-center"
                  style={{ height: "543px" }}
                >
                  {chartLoading ? (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" role="status"></Spinner>
                    </div>
                  ) : (
                    <>
                      <FormControl fullWidth className="graphSelectBox">
                        <InputLabel id="demo-simple-select-label">
                          Year
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedYear}
                          label="Age"
                          onChange={(e) => handleChangeYear(e)}
                        >
                          <MenuItem value={2022}>2022</MenuItem>
                          <MenuItem value={2023}>2023</MenuItem>
                          <MenuItem value={2024}>2024</MenuItem>
                          <MenuItem value={2025}>2025</MenuItem>
                        </Select>
                      </FormControl>
                      <Chart options={options} data={data} />
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Admin;
