import axios from "axios";
import { userCredentials, userLoginResponseDto } from "DTO/userLoginDto";
import {
  IUsers,
  MapDashboardParams,
  MetricsType,
  userWorkOrderListDto,
  workOrderInfoResponse,
} from "DTO/userWorkOrderListDto";
import { endPoints } from "./SeriviceEndpoints";
import { AppConfType, ApprovalResponseType } from "types";

export const userLogin = async (data: userCredentials) => {
  try {
    const user = await axios.post<userLoginResponseDto>(
      `${endPoints.login}`,
      data
    );
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw false;
  }
};

export const getAppConfig = async () => {
  try {
    const res = await axios.get<AppConfType>(`${endPoints.appconfig}`);
    if (res.status === 200) return res.data;
  } catch (error) {
    throw error;
  }
};

export const getWorkOrderList = async () => {
  try {
    const user = await axios.get<[userWorkOrderListDto]>(
      `${endPoints.getWorkOrderList}`
    );
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getWorkOrderListMap = async (params: MapDashboardParams) => {
  try {
    const user = await axios.get(
      `${endPoints.getWorkOrderListMap}` +
        `?zoom=` +
        params?.zoom +
        `&min_longitude=` +
        params?.min_latitude +
        `&min_latitude=` +
        params?.min_longitude +
        `&max_longitude=` +
        params?.max_latitude +
        `&max_latitude=` +
        params?.max_longitude +
        `&platform=` +
        params?.platform
    );
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getChartData = async (id) => {
  try {
    const user = await axios.get("/get-stats?platform=web&year=" + id);
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getActiveWorkOrder = async (type: string) => {
  try {
    const user = await axios.get(
      `/get-active-workorders?casetype=${type}&page_num=1&page_size=10000`
    );
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getWorkOrderListbyCaseStatus = async (
  status: String,
  pageSize: number,
  pageNum: number
) => {
  try {
    const user = await axios.get<[userWorkOrderListDto]>(
      `${endPoints.getWorkOrderByCaseStatus}`,
      {
        params: {
          casetype: "cable indication",
          casestatus: status,
          page_size: pageSize,
          page_num: pageNum,
        },
      }
    );
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getWorkOrderListbyCaseType = async (type, pageSize, pageNum) => {
  try {
    const user = await axios.get<[userWorkOrderListDto]>(
      `${endPoints.getWorkOrderByCaseType}`,
      { params: { casetype: type, page_size: pageSize, page_num: pageNum } }
    );
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getWorkOrderListPaged = async (pageSize, pageNum) => {
  // console.log("Where is it from?");
  try {
    const user = await axios.get<[userWorkOrderListDto]>(
      `${endPoints.getWorkOrderListPaged}`,
      { params: { page_size: pageSize, page_num: pageNum } }
    );
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getWorkerOrderInfo = async (caseId: string) => {
  try {
    const user = await axios.post<workOrderInfoResponse>(
      `${endPoints.getWorkorderInfo}`,
      {
        case_id: caseId,
      }
    );
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getWorkerOrderbyId = async (caseId: string) => {
  try {
    const order = await axios.get<userWorkOrderListDto>(
      `${endPoints.getWorkOrderById}?caseid=${caseId}`
    );
    if (order.status === 200) {
      return order.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getWorkOrderByCaseStatus = async (
  casetype: string,
  casestatus: string
) => {
  try {
    const user = await axios.get(
      `/get-workorder-by-case-status?casetype=${casetype}&casestatus=${casestatus}&page_num=1&page_size=10000`
    );
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getMetrics = async () => {
  try {
    const user = await axios.get<MetricsType>(
      `${endPoints.getDashboardMetrics}`
    );
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getGraphData = async () => {
  try {
    const user = await axios.get(`${endPoints.getGraphData}`);
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const updateWorkOrderStatus = async (
  case_id: string,
  status: string,
  CaseAction?: string
) => {
  const data = CaseAction
    ?   { Status: status, CaseActionOverride: CaseAction,  Comment: "Ingen åtgärd krävs, Ärendet avslutat" } 
    :   { Status: status } ;
  try {
    const state = JSON.stringify({
      workorder: {
        case_id: case_id,
      },
      data,
    });
    const res = await axios.post(`${endPoints.updateStatus}`, state);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    throw err;
  }
};

export const getUsersList = async () => {
  try {
    const user = await axios.get<IUsers>(`${endPoints.getTenantUsersList}`);
    if (user.status === 200) {
      return user.data;
    }
  } catch (err) {
    throw err;
  }
};

export const postUser = async (newUser) => {
  try {
    const res = await axios.post(`${endPoints.addTenantUser}`, newUser);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    throw err;
  }
};

export const deleteUser = async (user) => {
  try {
    const res = await axios.post(`${endPoints.deleteTenantUser}`, user);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    throw err;
  }
};

export const putUser = async (userData) => {
  try {
    const res = await axios.put(`${endPoints.resetUserPassword}`, userData);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    throw err;
  }
};

export const uploadCaseFile = async (WOId, file) => {
  try {
    let formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(
      `${endPoints.uploadProjectCaseFile}`,
      formData,
      { params: { case_id: WOId } }
    );
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    throw err;
  }
};

export const updateApproval = async (
  WOCaseId: string
): Promise<ApprovalResponseType> => {
  try {
    const res = await axios.put(
      `${endPoints.updateApproval}?case_id=${WOCaseId}`
    );
    if (res.status === 200) return res.data;
  } catch (err: any) {
    return err.message;
  }
};
