export const endPoints = {
    login: 'login',
    getWorkOrderList: 'get-workorder-list-v2?platform=web',
    getWorkOrderListMap: 'get-map-data',
    getWorkOrderByCaseStatus: 'get-workorder-by-case-status',
    getWorkOrderByCaseType: 'get-workorder-by-case-type',
    getWorkOrderById: 'get-workorder-by-id',
    getWorkOrderListPaged: 'get-workorder-list-paged?platform=web',
    getWorkorderInfo: 'get-workorder-info',
    getDashboardMetrics: 'get-dashboard-metrics',
    getGraphData: 'get-graph-data',
    updateStatus: 'update-workorder-status',
    getTenantUsersList: 'get-tenant-users',
    addTenantUser: 'add-tenant-user',
    deleteTenantUser: 'delete-tenant-user',
    uploadProjectCaseFile: 'upload-project-case-file',
    resetUserPassword: 'reset-user-password',
    updateApproval: 'update-approval',
    appconfig: 'env-vars'
};
