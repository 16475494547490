import styled from "styled-components";

export const ModalWrapper = styled.div`
position: fixed;
z-index: 99;
left: 0;
top: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
`;

export const SaveButton = styled.button`
  background-color: #06b4c8;
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #42db89;
  }
  &:disabled {
  background-color: #06b4c8;
  opacity:0.5;
  cursor: not-allowed;
  }  
`;

export const CancelButton = styled(SaveButton)`

  margin-left: 16px;

  &:hover {
    background-color:#5F0909;
    color: #42db89;
  }
`;