import { userWorkOrderListDto } from "DTO/userWorkOrderListDto";
import moment from "moment";
import {
  swedishIt,
  handleBgColor,
  handleGraphLanguages,
} from "providers/StylingFunc";
import {
  TableData,
  StatusCard,
  TableBody,
  TableRow,
} from "views/authenticated/styled";

function WorkOrderTableBody(
  displayData: userWorkOrderListDto[],
  activeActive: number | string,
  setActiveActive: React.Dispatch<React.SetStateAction<number | string>>,
  setSelectedUserData: (allData: any) => Promise<void>,
  selectedCaseId: userWorkOrderListDto
):
  | string
  | number
  | boolean
  | {}
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactNodeArray
  | React.ReactPortal {
  if (displayData?.length === 0 || !displayData)
    return (
      <TableBody>
        <TableRow header={true}>
          <TableData css="text-align: center" colSpan={8}>
            <strong> No data available</strong>
          </TableData>
        </TableRow>
      </TableBody>
    );
  const td = (text: string, css?: string) => {
    return <TableData css={css}>{text}</TableData>;
  };
  return (
    <TableBody>
      {displayData.map((v, i: number) => (
        <TableRow
          key={i + v.WOCaseId}
          isActive={activeActive === i + v.WOCaseId}
          css="cursor:pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setActiveActive(i + v.WOCaseId);
            setSelectedUserData(v);
          }}
        >
          {td(v.WOCaseId)}
          {td(
            handleGraphLanguages(v.Type),
            selectedCaseId ? "display: none" : ""
          )}
          {td(
            v.Location ?? "",

            selectedCaseId ? "display: none" : ""
          )}
          {td(moment(v?.StartDate).format("DD MMM YYYY") ?? "")}
          {td(
            v?.IquirerEmail,

            selectedCaseId ? "display: none" : ""
          )}
          {td(
            v.StatusUpdatedOn
              ? moment(v.StatusUpdatedOn).format("DD MMM YYYY")
              : ""
          )}
          {td(v?.Assignee, "font-size: 11px")}
          <TableData>
            {
              <StatusCard
                css={`
                  text-align: center;
                  background-color: ${v?.Approvals?.accepted === false
                    ? handleBgColor("approvals")
                    : handleBgColor(v.Status)};
                `}
              >
                {swedishIt(v.Status, v.InquiryType)}
              </StatusCard>
            }
          </TableData>
        </TableRow>
      ))}
    </TableBody>
  );
}

export default WorkOrderTableBody;
