import styled, { keyframes } from "styled-components";

import { Link } from "react-router-dom";
import BackgroundImage from "../../resources/images/svg/new-login-bg.svg";
import { green, lightGreen, darkGrey, red } from "../../resources/colors";
import { turquoise, darkGrey as dGrey } from "../../resources/newColors";
import { ItemContainerAnimation } from "../../pandora/animations";

export const Flex = styled("div")<{ direction: string; css?: string }>`
	display: flex;
	flex-direction: ${(props) => props.direction};
	height: 100%;
	width: 100%;
	${(props) => props.css ?? ""}
`;

export const HorizontalFlex = styled("div")<{ css?: string }>`
	display: flex;
	width: auto;
	height: auto;
	${(props) => props.css ?? ""}
`;

export const SpaceBetweenRow = styled("div")<{ css?: string }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	${(props) => props.css ?? ""}
`;

export const CenterRow = styled("div")<{ css?: string }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	${(props) => props.css ?? ""}
`;

export const VerticalFlex = styled("div")<{ css?: string }>`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	${(props) => props.css ?? ""}
`;

export const OverlayLayer = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const PageTitle = styled("h1")<{ fontSize?: number }>`
	font-size: ${(props) => props.fontSize ?? 35}px;
	color: white;
	text-align: left;
	margin: 0;
`;

export const PostfixIcon = styled("img")<{ css?: string }>`
	margin: 0;
	${(props) => props.css ?? ""};
`;

export const PasswordInput = styled("input")<{
	focusBorder?: boolean;
	borderColor?: string;
	fontSize?: number;
	css?: string;
}>`
	font-size: ${(props) => props.fontSize ?? 11}px;
	color: ${darkGrey()};
	text-align: left;
    flex; 1;
    width: 100%;
    min-height: 45px;
    height: 100%;
    padding-left: 10px;
	margin: 0;
    border: none;
    outline: none;
	::placeholder {
		color: ${darkGrey()};
		opacity: 50%;
		font-size: ${(props) => props.fontSize ?? 11}px;
	}
	${(props) => props.css ?? ""};
`;

export const PasswordInputContainer = styled("div")<{
	focusBorder?: boolean;
	borderColor?: string;
	fontSize?: number;
	css?: string;
}>`
	display: flex;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: center;
	position: relative;
	margin: 0;
	margin-top: 5px;
	height: 45px;
	width: 100%;
	overflow: hidden;
	border: 1px solid #c4c4c4;
	padding: 10px;
	padding-left: 0;
	background: #fff;
	text-align: left;
	font-family: Montserrat, sans-serif;
	font-weight: normal;
	box-sizing: border-box;
	transition: 0.3s ease;
	${(props) => props.css ?? ""};
`;

export const Input = styled("input")<{
	focusBorder?: boolean;
	borderColor?: string;
	fontSize?: number;
	css?: string;
}>`
	position: relative;
	margin: 0;
	margin-top: 5px;
	height: 45px;
	width: 100%;
	outline: none;
	border: 1px solid #c4c4c4;
	padding: 10px;

	background: #fff;

	text-align: left;
	color: ${darkGrey()};
	font-size: ${(props) => props.fontSize ?? 11}px;
	font-family: Montserrat, sans-serif;
	font-weight: normal;

	box-sizing: border-box;

	transition: 0.3s ease;

	::placeholder {
		color: ${darkGrey()};
		opacity: 50%;
		font-size: ${(props) => props.fontSize ?? 11}px;
	}
	&:focus {
		background-color: #fff;
	}

	${(props) => props.css ?? ""};
`;

export const Button = styled("div")<{
	color: string;
	hoverColor: string;
	css?: string;
}>`
	color: white;
	line-height: 40px;
	font-size: 13px;
	font-weight: bold;

	user-select: none;
	cursor: pointer;

	background-color: ${(props) => props.color};

	background-position: center;
	transition: background 0.8s;

	&: hover {
		background: ${(props) => props.color}
			radial-gradient(circle, transparent 1%, ${(props) => props.color} 1%)
			center/15000%;
	}
	&: active {
		background-color: ${(props) => props.hoverColor};
		background-size: 100%;
		transition: background 0s;
	}

	${(props) => props.css ?? ""}
`;

export const MessageDiv = styled.div`
	position: relative;
	width: auto;
	height: fit-content;
	padding: 10px;
	margin-bottom: 20px;

	font-size: 12px;
	font-weight: bold;

	animation: ${ItemContainerAnimation} 0.3s 1;
`;
export const ErrorDiv = styled(MessageDiv)`
	background: rgba(255, 80, 80, 0.2);
	color: ${red};
	border: 1px solid ${red};
	border-radius: 5px;
`;

export const SuccessDiv = styled(MessageDiv)`
	background: rgba(25, 139, 116, 0.2);
	color: ${lightGreen};
	border: 1px solid ${green};
	border-radius: 5px;
`;

export const Logo = styled.img`
	width: 50px;
	height: auto;
	margin-right: 15px;
`;

export const ViewContainer = styled.div`
	position: relative;
	min-height: 100%;
	height: auto;
	overflow: auto;
`;

export const Background = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	background-image: url(${BackgroundImage});
	background-size: cover;
	background-repeat: no-repeat;
`;

export const Container = styled(VerticalFlex)`
	position: relative;
	background-color: transparent;
	align-items: center;
	width: 100%;
	min-width: 350px;
	height: 100%;
	box-sizing: border-box;
	overflow: scroll;
`;

export const LogoContainer = styled(HorizontalFlex)`
	flex: 1;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
`;

export const SiteTitle = styled.span`
	color: ${dGrey};
	font-size: 28px;
	font-weight: 900;
`;

export const HeaderText = styled.span`
	color: ${dGrey};
	font-size: 20px;
	font-weight: 800;
`;

export const InputContainer = styled(VerticalFlex)`
	flex: 1;
	justify-content: center;
	padding: 10px 0;
	text-align: left;
	align-items: flex-start;
	width: 100%;
`;

export const ButtonContainer = styled(VerticalFlex)`
	flex: 1;
	justify-content: center;
	text-align: center;
	align-items: center;
	width: 100%;
}
`;

export const InputLabel = styled(HorizontalFlex)`
	color: ${dGrey};
	font-size: 12px;
	padding: 5px 0;
`;

export const ForgotLink = styled(Link)`
	color: ${turquoise};
	cursor: pointer;
	text-decoration: none;
	font-size: 12px;
	font-weight: 700;
	margin-left: 5px;
	&:hover {
		text-decoration: none;
		color: ${turquoise};
	}
`;

export const ContactUsLink = styled(Link)`
	color: ${turquoise};
	cursor: pointer;
	text-decoration: none;
	font-size: 12px;
	font-weight: 700;
	margin-left: 5px;
	&:hover {
		text-decoration: none;
		color: ${turquoise};
		font-size: 14px;
	}
`;

export const FooterLinkContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
`;

export const FooterLink = styled(Link)`
	color: #444;
	cursor: pointer;
	text-decoration: none;
	font-size: 12px;
	font-weight: 700;
	&:hover {
		text-decoration: none;
		color: #000;
		font-size: 14px;

	}
`;

export const SaveLoginsContainer = styled(HorizontalFlex)`
	box-sizing: border-box;
	display: flex;
	align-items: center;
	margin: 20px 0;
	color: ${dGrey};
	font-size: 12px;
	padding: 5px 0;
`;

export const SaveLoginsInner = styled("div")<{
	backgroundColor?: string;
}>`
	background-color: ${(props) => props.backgroundColor};
	width: 10px;
	height: 10px;
`;

export const SaveLoginsOuter = styled("div")<{
	backgroundColor?: string;
}>`
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	cursor: pointer;
	width: 14px;
	height: 14px;
	border: thin solid #c4c4c4;
	margin-right: 10px;
`;

export const LoginContainer = styled(VerticalFlex)`
	margin-top: 2%;
	width: 30%;
	min-width: 388px;
	height: auto;
	align-items: center;
	text-align: left;
	background-color: transparent;
	animation: ${ItemContainerAnimation} 0.8s 1;
`;

export const LoginSubContainer = styled(VerticalFlex)`
	margin-top: 10%;
	padding: 40px 50px;
	background-color: #fff;
	border: thin solid #42db89;
	box-shadow: 5px 5px #42db89;
`;
