import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import MapGl from "components/MapGl";

export const MapModal = ({ show, setShow, viewport, activeCaseId }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)} className="mapZoomModel">
      <Modal.Header className="border-0">
        {" "}
        <CloseIcon className="bg-light" onClick={() => setShow(false)} />
      </Modal.Header>
      <Modal.Body>
        {viewport && (
          <MapGl activeCaseId={activeCaseId} showBigMap={setShow} show={show} />
        )}
      </Modal.Body>
    </Modal>
  );
};
